// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.App {
  text-align: center;
  padding: 20px;
}

h1 {
  color: #333;
  font-size: 3rem;
  margin-bottom: 2rem;
}

.results {
  margin: 40px 0;
  font-size: 2rem;
  color: #000;
}

.result-value {
  font-size: 5rem;
  font-weight: bold;
  margin-top: 20px;
  color: #e74c3c;
}

.test-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
}

button {
  padding: 20px 40px;
  font-size: 1.5rem;
  color: #fff;
  background-color: #3498db;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #2980b9;
}

.test-result {
  font-size: 1.5rem;
  color: #333;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,gCAAgC;EAChC,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;EACX,yBAAyB;EACzB,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,gBAAgB;AAClB","sourcesContent":["body {\n  margin: 0;\n  font-family: 'Arial', sans-serif;\n  background-color: #f5f5f5;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\n\n.App {\n  text-align: center;\n  padding: 20px;\n}\n\nh1 {\n  color: #333;\n  font-size: 3rem;\n  margin-bottom: 2rem;\n}\n\n.results {\n  margin: 40px 0;\n  font-size: 2rem;\n  color: #000;\n}\n\n.result-value {\n  font-size: 5rem;\n  font-weight: bold;\n  margin-top: 20px;\n  color: #e74c3c;\n}\n\n.test-buttons {\n  display: flex;\n  justify-content: center;\n  gap: 20px;\n  margin-top: 40px;\n}\n\nbutton {\n  padding: 20px 40px;\n  font-size: 1.5rem;\n  color: #fff;\n  background-color: #3498db;\n  border: none;\n  border-radius: 50px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\nbutton:hover {\n  background-color: #2980b9;\n}\n\n.test-result {\n  font-size: 1.5rem;\n  color: #333;\n  margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

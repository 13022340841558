import React, { useState } from 'react';
import { LinearProgress, Button, Container, Typography, Box } from '@mui/material';

const UploadSpeedTest = () => {
  const [uploadSpeed, setUploadSpeed] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploadedSize, setUploadedSize] = useState(0);
  const [fileSizeInMB, setFileSizeInMB] = useState(0);

  const runUploadTest = async () => {
    const apiUrl = process.env.REACT_APP_API_URL || 'https://backend:5001';

    const file = new Blob([new Uint8Array(10 * 1024 * 1024)]); // 10MB dosya
    const formData = new FormData();
    formData.append('file', file);
    const fileSizeMB = file.size / (1024 * 1024);
    setFileSizeInMB(fileSizeMB.toFixed(2)); // Dosya boyutu MB

    const xhr = new XMLHttpRequest();
    
    xhr.open('POST', `${apiUrl}/upload`, true);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        setProgress(percentComplete);
        setUploadedSize((event.loaded / (1024 * 1024)).toFixed(2)); // Yüklenen dosya boyutu MB
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        const endTime = performance.now();
        const duration = (endTime - startTime) / 1000; // Saniye
        const uploadSpeed = fileSizeMB / duration; // MB/s
        setUploadSpeed(uploadSpeed.toFixed(2)); // Yükleme hızı
      } else {
        console.error('Upload failed:', xhr.responseText);
        setUploadSpeed('Hata');
      }
    };

    xhr.onerror = () => {
      console.error('Upload failed:', xhr.responseText);
      setProgress(0);
      setUploadSpeed('Hata');
    };

    const startTime = performance.now();
    xhr.send(formData); // Yükleme
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 4 }}>
      <Typography variant="h4" gutterBottom>Yükleme Testi</Typography>

      <Box sx={{ mb: 2 }}>
        <Button variant="contained" color="primary" onClick={runUploadTest}>
          Yükleme Testini Başlat
        </Button>
      </Box>

      {progress > 0 && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6">Test İlerlemesi: {Math.round(progress)}%</Typography>
          <LinearProgress variant="determinate" value={progress} />
          <Typography variant="body1">{uploadedSize} MB / {fileSizeInMB} MB</Typography>
        </Box>
      )}

      {uploadSpeed && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6">Yükleme Hızı: {uploadSpeed} MB/s</Typography>
        </Box>
      )}
    </Container>
  );
};

export default UploadSpeedTest;
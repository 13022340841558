import React, { useState } from 'react';
import { LinearProgress, Button, Container, Typography, Box } from '@mui/material';
import { Line } from 'react-chartjs-2';
import './App.css'; 

const DownloadSpeedTest = () => {
  const [downloadSpeed, setDownloadSpeed] = useState(null);
  const [progress, setProgress] = useState(0);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Bağlantı Hızı (MB/s)',
        data: [],
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
      }
    ]
  });

  const runDownloadTest = async () => {
    const apiUrl = process.env.REACT_APP_API_URL || 'https://backend:5001';

    try {
      const response = await fetch(`${apiUrl}/download`);
      const reader = response.body.getReader();
      const contentLength = response.headers.get('Content-Length');

      let receivedLength = 0;
      let chunks = [];
      let done = false;
      const startTime = performance.now();

      let speedHistory = [];
      let timeLabels = [];

      while (!done) {
        const { done: readerDone, value } = await reader.read();
        if (readerDone) {
          done = true;
          break;
        }
        chunks.push(value);
        receivedLength += value.length;

        const currentTime = performance.now();
        const duration = (currentTime - startTime) / 1000;
        const fileSizeInMB = receivedLength / (1024 * 1024);
        const downloadSpeed = fileSizeInMB / duration;

        speedHistory.push(downloadSpeed.toFixed(2));
        timeLabels.push(duration.toFixed(2));

        setDownloadSpeed(downloadSpeed.toFixed(2));
        setProgress((receivedLength / contentLength) * 100);

        setChartData({
          labels: timeLabels,
          datasets: [
            {
              label: 'Bağlantı Hızı (MB/s)',
              data: speedHistory,
              borderColor: 'rgba(75, 192, 192, 1)',
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              fill: true,
            }
          ]
        });
      }

    } catch (error) {
      console.error('Download failed:', error);
      setDownloadSpeed('Hata');
      setProgress(0);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 4 }}>
      <Typography variant="h4" gutterBottom>İnternet Hız Testi</Typography>

      <Box sx={{ mb: 2 }}>
        <Button variant="contained" color="primary" onClick={runDownloadTest}>
          İndirme Testini Başlat
        </Button>
      </Box>

      {progress > 0 && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6">Test İlerlemesi: {Math.round(progress)}%</Typography>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      )}

      {downloadSpeed && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6">İndirme Hızı: {downloadSpeed} MB/s</Typography>
        </Box>
      )}

      {progress > 0 && (
        <Box sx={{ mb: 4 }}>
          <Line data={chartData} options={{ maintainAspectRatio: false }} height={200} />
        </Box>
      )}
    </Container>
  );
};

export default DownloadSpeedTest;
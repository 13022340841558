import React from 'react';
import './App.css';
import DownloadSpeedTest from './DownloadSpeedTest';
import UploadSpeedTest from './UploadSpeedTest';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  
  // Chart.js'in scale ve bileşenlerini kaydediyoruz
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

function App() {
  return (
    <div className="App">
      <h1>İnternet Hız Testi Uygulaması</h1>
      <DownloadSpeedTest /> {/* İndirme testi bileşenini burada kullanıyoruz */}
      <UploadSpeedTest /> {/* Yükleme testi bileşenini burada kullanıyoruz */}
    </div>
  );
}

export default App;